@tailwind base;
@tailwind components;
@tailwind utilities;

body{
  background-color: #000300;
}

/* src/components/HeroSection.css */
.hero-section {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: #000;
}

.canvas-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0; /* Ensure the canvas is behind the content */
}

.content {
  position: relative;
  z-index: 1;
  color: white;
  text-align: center;
}

.content h1 {
  font-size: 3rem;
  margin: 0;
}

.content p {
  font-size: 1.5rem;
}

.content button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
}
